import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { styled } from "@mui/system";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import { PrivateRoute } from "./components/privateroute";
import Rightbar from "./components/rightbar";
import Sidebar from "./components/sidebar";
import Cronjobs from "./scenes/cronjobs";
import ErrorPage from "./scenes/error-page";
import Feed from "./scenes/feed";
import Impressum from "./scenes/impressum";
import Insider from "./scenes/insider";
import Detailview from "./scenes/portfolio/detailview";
import AiAnalyser from "./scenes/aiAnalyser";
import Portfolio from "./scenes/portfolio/portfolio";
import TransactionDividend from "./scenes/portfolio/transactiondividend";
import Screener from "./scenes/screener";
import Secdetail from "./scenes/secdetail";
import Settings from "./scenes/settings";
import Stocks from "./scenes/stocks";
import Users from "./scenes/users";
import { ColorModeContext, useMode } from "./theme/theme";
import Dividends from "./scenes/dividends";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Accounts from "./scenes/accounts";
import AccountTransaction from "./scenes/accountTransaction";
import HelpPage from "./scenes/helpPage";
import ErrorTech from "./scenes/error-tech";

const MainLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 0,
  paddingRight: 0,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 240
  }
}));

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
            <CssBaseline />
            <MainLayoutRoot>
              {/* Mainbox to display all contents */}
              <Box id="mainBox" width="100%">
                <Navbar id="navBar" onSidebarOpen={() => setSidebarOpen(true)} />
                <Sidebar onClose={() => setSidebarOpen(false)} open={isSidebarOpen} />
                <Routes>
                  <Route path="*" element={<PrivateRoute><Feed /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/stocks" element={<PrivateRoute><Stocks /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/dividends" element={<PrivateRoute><Dividends /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/insider" element={<PrivateRoute><Insider /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/screener" element={<PrivateRoute><Screener /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/portfolio" element={
                    <PrivateRoute>
                      <Portfolio />
                    </PrivateRoute>
                  } errorElement={<ErrorPage />} />
                  <Route path="/detailview" element={<PrivateRoute><Detailview /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/aianalyser" element={<PrivateRoute><AiAnalyser /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/accounts" element={<PrivateRoute><Accounts /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/accounttransaction" element={<PrivateRoute><AccountTransaction /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/transactiondividend" element={<PrivateRoute><TransactionDividend /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/users" element={<PrivateRoute adminOnly><Users /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/cronjobs" element={<PrivateRoute adminOnly><Cronjobs /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/help" element={<PrivateRoute><HelpPage /></PrivateRoute>} errorElement={<ErrorPage />} />
                  <Route path="/impressum" element={<Impressum />} errorElement={<ErrorPage />} />
                  <Route path="/error-tech" element={<ErrorTech />} errorElement={<ErrorPage />} />
                  <Route path="/secdetail/:identifier" element={<PrivateRoute><Secdetail /></PrivateRoute>} errorElement={<ErrorPage />} />
                </Routes>
                <Rightbar />
                <Footer />
              </Box>
            </MainLayoutRoot>
          </LocalizationProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

export default App;
