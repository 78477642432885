import { useCallback, useContext, useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid,
    Typography,
    Select,
    MenuItem,
    FormControl, FormGroup, FormControlLabel, Checkbox,
    Stack,
    Alert,
    CircularProgress,
} from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CompanyAvatar from '../components/CompanyAvatar';
import { UserContext } from '../theme/userContext';
import { useLocation, useNavigate } from "react-router-dom";
import { formatDateTime } from "../misc/helperfunctions";
import ReactMarkdown from 'react-markdown';

const AiAnalyser = () => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const isAdmin = user && user.role === 'admin';
    const location = useLocation();
    const { state } = location;
    let identifier;
    if (state) {
        identifier = state.identifier;
    }

    const handleCloseButtonClick = () => {
        navigate(-1);
    };

    const [aiResponses, setAiResponses] = useState([]);
    const [selectedResponseId, setSelectedResponseId] = useState(null);
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');
    const [llmprovider, setLlmprovider] = useState('');
    const [type, setType] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [loading, setLoading] = useState(false); // Zustand für die Ladeanzeige

    useEffect(() => {
        fetchModels();
    }, []);

    async function fetchModels() {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/ai_model_weights`);
            const data = await response.json();
            setModels(data);
            console.log(data);
            if (data.length > 0) {
                setSelectedModel(data[0].model);
                setLlmprovider(data[0].llmprovider);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function fetchSecurity() {
        try {
            if (identifier && identifier.length > 1) {
                let url = process.env.REACT_APP_API_URI + "/api/airesponse?identifier=" + identifier;
                const response = await fetch(url);
                const data = await response.json();
                setAiResponses(data);

                // Initialisiere mit dem ersten Eintrag
                if (data.length > 0) {
                    setSelectedResponseId(data[0].id);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const fetchSecurityCallback = useCallback(fetchSecurity, [identifier]);
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchSecurityCallback();
    }, [fetchSecurityCallback]);

    const handleSelectChange = (event) => {
        setSelectedResponseId(event.target.value);
    };

    const handleModelChange = (event) => {
        const selected = models.find(model => model.model === event.target.value);
        if (selected) {
            setSelectedModel(selected.model);
            setLlmprovider(selected.llmprovider);
        }
    };

    const handleTypeChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setType((prev) => [...prev, value]); // Hinzufügen eines neuen Wertes
        } else {
            setType((prev) => prev.filter((item) => item !== value)); // Entfernen eines Wertes
        }
    };

    const handleStartProcessing = async () => {
        setShowMessage(true);
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/process_single_identifier`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    identifier,
                    type,
                    model: selectedModel,
                    llmprovider,
                    securities: 'MANUELL' // Beispielwert, kann angepasst werden
                })
            });

            const data = await response.json();
            console.log(data);

        } catch (error) {
            console.error(error);
        } finally {
            // Verstecke die Nachricht nach 3 Sekunden, unabhängig vom API-Ergebnis
            setTimeout(() => setShowMessage(false), 10000);
            setLoading(false);

            // Seite neu laden, um Ergebnisse anzuzeigen
            setTimeout(() => {
                window.location.reload(); // Neu laden der aktuellen Seite
            }, 500); // Wartezeit optional anpassbar
        }
    };

    const selectedResponse = aiResponses.find(response => response.id === parseInt(selectedResponseId, 10));
    const selectedFullText = selectedResponse ? selectedResponse.ai_response_full : '';

    return (
        <Box id="aiDetailBox" m="10px">
            {/* Header */}
            <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center">
                <Grid item xs={12}>
                    <Button startIcon={<KeyboardBackspaceIcon />} onClick={handleCloseButtonClick}>
                        zurück
                    </Button>
                </Grid>
                <Grid item xs={2} sm={1} lg={1} minWidth="100px">
                    <CompanyAvatar identifier={identifier || '???'} alt="Company Logo"
                        sx={{
                            marginLeft: 3,
                            height: 70,
                            width: 70,
                        }}
                    />
                </Grid>
                <Grid item>
                    {identifier && (
                        <Typography variant="h4">
                            {aiResponses.length > 0 ? aiResponses[0].name : identifier}
                        </Typography>
                    )}
                    {loading && (
                        <CircularProgress
                            size={30}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    )}
                </Grid>
                {/* Combobox rechts neben der Grid-Komponente */}
                <Grid item xs="auto" ml="auto">
                    <Select
                        value={selectedResponseId || ''}
                        onChange={handleSelectChange}
                        displayEmpty
                        renderValue={(value) => {
                            if (value === '') {
                                return <em>Select an analysis</em>;
                            }
                            const response = aiResponses.find(resp => resp.id === parseInt(value, 10));
                            if (response) {
                                const formattedDate = formatDateTime(response.date);
                                return `${formattedDate} - ${response.type} - ${response.model} - ${response.ai_score} Punkte`;
                            }
                            return '';
                        }}
                    >
                        {aiResponses.map(response => (
                            <MenuItem key={response.id} value={response.id}>
                                {`${formatDateTime(response.date)} - ${response.type} - ${response.model} - ${response.ai_score} Punkte`}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>

            {/* Text Field für AI Response */}
            <Box
                mt={2}
                sx={{
                    backgroundColor: '#f9f9f9', // Weißer Hintergrund
                    padding: 2,              // Innenabstand (Padding)
                    borderRadius: 2,         // Abgerundete Ecken (optional)
                    boxShadow: 1,            // Schatten für eine leichte Erhebung
                    margin: 2                // Gleichmäßiges Margin zu allen Seiten
                }}
            >
                {selectedResponse && (
                    <Typography variant="h6" gutterBottom>
                        KI-Analyse vom:{' '}
                        <Typography component="span" sx={{ fontWeight: "bold" }}>
                            {selectedResponse.date ? formatDateTime(selectedResponse.date) : 'Datum nicht verfügbar'}
                        </Typography>
                        {' '}mit dem Modell: {selectedResponse.model || 'Modell nicht verfügbar'}
                        {' '}und dem
                        <Typography component="span" sx={{ fontWeight: "bold" }}>
                            {' '}Ergebnis:{' '} {selectedResponse.ai_score || '?'}
                        </Typography>
                        {' '}von max. 12 Punkten
                    </Typography>
                )}
                <ReactMarkdown>{selectedFullText}</ReactMarkdown>
            </Box>

            {isAdmin && (<Box mt={4} sx={{
                padding: 2, border: '1px solid #ccc', borderRadius: 2, backgroundColor: '#f9f9f9', boxShadow: 1,            // Schatten für eine leichte Erhebung
                margin: 2
            }}>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    Nur für Admins: Neue KI-Anfrage erstellen
                </Typography>
                <Stack direction="row" spacing={2} alignItems="center">

                    <Select
                        labelId="model-select-label"
                        value={selectedModel}
                        label="Modell"
                        onChange={(e) => {
                            // Hier den ausgewählten Wert setzen
                            setSelectedModel(e.target.value);
                            // Optional: provider gleich mitsetzen, 
                            // wenn du das 1:1 aus dem Array nimmst:
                            const mod = models.find(m => m.model === e.target.value);
                            if (mod) {
                                setLlmprovider(mod.llmprovider);
                            }
                        }}
                    >
                        {models.map((m) => (
                            <MenuItem key={m.model} value={m.model}>
                                {m.model} (Gewicht: {m.weight}, Provider: {m.llmprovider})
                            </MenuItem>
                        ))}
                    </Select>

                    <Select
                        labelId="type-select-label"
                        value={type}
                        onChange={(event) => setType(event.target.value)}
                        displayEmpty
                        variant="outlined"
                        sx={{ minWidth: 200 }}
                    >
                        <MenuItem value="" disabled>
                            <em>Wähle einen Typ</em>
                        </MenuItem>
                        <MenuItem value="SCREENER">SCREENER</MenuItem>
                        <MenuItem value="ANALYSIS">ANALYSIS</MenuItem>
                        <MenuItem value="TECHNICALS">TECHNICALS</MenuItem>
                    </Select>


                    {/* Start Processing Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleStartProcessing}
                        sx={{ mt: 2 }}
                        disabled={!type || type === ""} // Button ist deaktiviert, wenn `type` leer ist
                    >
                        Start Processing
                    </Button>

                </Stack>
                <Box mt={2}>
                    {showMessage && (
                        <Alert severity="info">
                            KI-Anfrage gestartet. Das kann einige Minuten dauern. Kommen Sie später zurück... :-)
                        </Alert>
                    )}
                </Box>
            </Box>
            )}
        </Box>
    );
};

export default AiAnalyser;