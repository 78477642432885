import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Box, Button, Divider, Fade, FormControlLabel, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Switch, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Linechart from '../../components/linechart';
import { formatDateLong, getCurrencySymbol } from "../../misc/helperfunctions";
import { tokens } from "../../theme/theme";
import Porttransactions from './porttransactions';
import { UserContext } from '../../theme/userContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CompanyAvatar from '../../components/CompanyAvatar';
import { Link as MUILink } from '@mui/material';
import BarChartDivYear from '../../components/barchartDivYear';
import { Typoheader } from '../../components/typoheader';
import { Typofield } from '../../components/typofield';
import ReactMarkdown from 'react-markdown'; // Markdown Renderer
import { formatDateTime } from "../../misc/helperfunctions";

const helpPerformance = `Gewinn / Verlust:
Der Gewinn oder Verlust bezieht sich auf die Differenz zwischen dem Kaufpreis und dem Verkaufspreis. Wenn der Verkaufspreis höher ist als der Kaufpreis, spricht man von einem Gewinn. Wenn der Verkaufspreis niedriger ist als der Kaufpreis, spricht man von einem Verlust.

Gewinn / Verlust %:
Die prozentuale Veränderung des Gewinns oder Verlusts im Verhältnis zum ursprünglichen Investitionsbetrag.

Realisiert G/V:
Der realisierte Gewinn oder Verlust bezieht sich auf den bereits realisierten Gewinn oder Verlust aus dem Verkauf von Aktien.

Abs. Performance:
Die absolute Performance gibt an, wie viel sich ein Portfolio oder eine Aktie insgesamt verändert hat, unabhängig davon, ob es sich um Gewinne oder Verluste handelt. Es wird als absolute Zahl oder Prozentsatz ausgedrückt.
Die Formel lautet: Abs. Performance = Kursgewinne + Realisierte Gewinne + Brutto Dividende - Steuer in EUR`;

const Detailview = () => {

    const { user } = useContext(UserContext);
    const location = useLocation();
    const { state } = location;
    let identifier, portfolioid;
    if (state) {
        identifier = state.identifier;
        portfolioid = state.portfolioid;
    }

    const navigate = useNavigate();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));
    const colors = tokens(theme.palette.mode);
    const [highlights, setHighlights] = useState({});
    const [pricePerformance, setPricePerfomance] = useState({});
    const [etfData, setEtfData] = useState({});
    const [divByYear, setDivByYear] = useState([]);
    const [limit, setLimit] = useState(true); // Limit 5 for dividends table
    const [dividends, setDividends] = useState([]);
    const [perYear, setPerYear] = useState(true); // dividends group by year
    const [security, setSecurity] = useState({});
    const [portfolio, setPortfolio] = useState({});
    const [isin, setIsin] = useState('');
    const [aiResponses, setAiResponses] = useState([]);
    const [lastAiResponse, setLastAiResponse] = useState(''); // Speichert den letzten Text

    const [anchorEl, setAnchorEl] = useState(null);
    const actionDialogOpen = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Fetch Highlights
    async function fetchHightlights() {
        try {
            if (identifier && identifier.length > 1) {
                let url = process.env.REACT_APP_API_URI + "/api/highlights?identifier=" + identifier;
                const response = await fetch(url);
                const data = await response.json();
                console.log("fetchHightlights");
                console.log(data);
                setHighlights(data);
            }
        } catch (error) {
            console.error(error);
        }
    }
    // Fetch priceperformance
    async function fetchPriceperformance() {
        try {
            if (identifier && identifier.length > 1) {
                let url = process.env.REACT_APP_API_URI + "/api/priceperformance?identifier=" + identifier;
                const response = await fetch(url);
                const data = await response.json();
                setPricePerfomance(data);
            }
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        console.log("useEffect fetchHightlightsCallback")
        fetchHightlights();
        fetchPriceperformance();
        // eslint-disable-next-line
    }, [identifier]);

    // Fetch Security
    async function fetchSecurity() {
        try {
            if (identifier && identifier.length > 1) {
                let url = process.env.REACT_APP_API_URI + "/api/security?identifier=" + identifier;
                const response = await fetch(url);
                const data = await response.json();
                console.log(data);
                setSecurity(data);
                if (data.length > 0 && data[0].isin) {
                    setIsin(data[0].isin);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    const fetchSecurityCallback = useCallback(fetchSecurity, [identifier]);
    useEffect(() => {
        console.log("useEffect fetchSecurityCallback")
        window.scrollTo(0, 0);
        fetchSecurityCallback();
    }, [fetchSecurityCallback]);

    // Fetch etfData
    async function fetchEtfData() {
        try {
            if (identifier && identifier.length > 1) {
                let url = process.env.REACT_APP_API_URI + "/api/etfData?identifier=" + identifier;
                const response = await fetch(url);
                const data = await response.json();
                console.log(data);
                setEtfData(data[0]);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const fetchEtfDataCallback = useCallback(fetchEtfData, [identifier]);
    useEffect(() => {
        console.log("useEffect fetchEtfDataCallback")
        fetchEtfDataCallback();
    }, [fetchEtfDataCallback]);

    // Fetch divByYear
    async function fetchDivByYear() {
        try {
            if (identifier && identifier.length > 1) {
                let url = process.env.REACT_APP_API_URI + "/api/dividendsbyyear?identifier=" + identifier;
                url += "&order=desc";
                const response = await fetch(url);
                const data = await response.json();
                console.log(data);
                setDivByYear(data);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const fetchDivByYearCallback = useCallback(fetchDivByYear, [identifier]);
    useEffect(() => {
        console.log("useEffect fetchDivByYearCallback")
        fetchDivByYearCallback();
    }, [fetchDivByYearCallback]);

    // Fetch divByYear
    async function fetchDividends() {
        try {
            if (identifier && identifier.length > 1) {
                let url = process.env.REACT_APP_API_URI + "/api/dividends?identifier=" + identifier;
                url += "&order=desc";
                const response = await fetch(url);
                const data = await response.json();
                console.log(data);
                setDividends(data);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const fetchDividendsCallback = useCallback(fetchDividends, [identifier]);
    useEffect(() => {
        console.log("useEffect fetchDividendsCallback")
        fetchDividendsCallback();
    }, [fetchDividendsCallback]);

    // Funktion zum Begrenzen der Daten auf 5 Elemente
    const getLimitedData = (data) => {
        if (limit) {
            return data.slice(0, 5); // nur die ersten 5 Elemente des Arrays zurückgeben
        }
        return data; // sonst das gesamte Array zurückgeben
    };

    // Fetch AI Responses
    async function fetchAiResponses() {
        try {
            if (identifier && identifier.length > 1) {
                const url = process.env.REACT_APP_API_URI + "/api/airesponse?identifier=" + identifier;
                const response = await fetch(url);
                const data = await response.json();
                setAiResponses(data);

                // Finden Sie das erste Vorkommen, bei dem type === 'ANALYSIS'
                const analysisEntry = data.find(entry => entry.type === 'ANALYSIS');

                // Setzen Sie den letzten Eintrag basierend auf der gefundenen Analyse
                if (analysisEntry) {
                    setLastAiResponse(analysisEntry.ai_response_full);
                } else {
                    setLastAiResponse("Keine KI-Analyse für dieses Unternehmen verfügbar.");
                }

            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchAiResponses();
    }, [identifier]);


    // Fetch Portfolio
    async function fetchPortfolio() {
        try {

            let url = '';
            if (portfolioid) {
                url = process.env.REACT_APP_API_URI + "/api/portfoliobyid?id=" + portfolioid;
            } else {
                url = process.env.REACT_APP_API_URI + "/api/portfoliobyidentifier?identifier=" + identifier + "&puser=" + user.p_user;
            }
            console.log(url);
            const response = await fetch(url);
            const data = await response.json();
            console.log(data);
            setPortfolio(data[0]);
            if (data.length > 0 && data[0].isin) {
                setIsin(data[0].isin);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const fetchPortfolioCallback = useCallback(fetchPortfolio, [portfolioid, identifier, user]);
    useEffect(() => {
        console.log("useEffect fetchPortfolioCallback")
        fetchPortfolioCallback();
    }, [fetchPortfolioCallback]);


    const handleCloseButtonClick = () => {
        navigate(-1);
    }

    /** Logo upload for admins */
    async function handleFileUpload(event) {
        try {
            event.preventDefault();
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            formData.append('identifier', identifier);
            let url = process.env.REACT_APP_API_URI + "/api/upload";
            await fetch(url, {
                method: 'POST',
                body: formData
            })
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));

            // Seite vollständig neu laden
            window.location.reload();

        } catch (error) {
            console.error(error);
        }
    };

    const handleFileInput = (e) => {
        handleFileUpload(e);
    }

    const Smartbox = styled(Box)(({ theme }) => ({
        display: 'flex',
        backgroundColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
        borderRadius: "8px",
        padding: "10px",
        paddingBottom: "20px",
    }));
    const Smartbox2 = styled(Box)(({ theme }) => ({
        display: 'flex',
        backgroundColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
        borderRadius: "8px",
        padding: "10px",
        paddingBottom: "10px",
    }));

    return (
        <Box id="stockDetailBox" m="10px" >
            <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} alignItems="center">
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent='space-between' alignItems="center">
                        <Button startIcon={<KeyboardBackspaceIcon />} onClick={() => handleCloseButtonClick()}>
                            zurück
                        </Button>
                        <Stack direction="row" justifyContent='flex-end' alignItems="center">
                            <Tooltip title="Wertpapier kaufen...">
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={actionDialogOpen ? 'fade-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={actionDialogOpen ? 'true' : undefined}
                                    component={Link}
                                    to={{
                                        pathname: "/transactiondividend",
                                    }}
                                    state={{ identifier: identifier, type: 'BUY' }}
                                >
                                    <AddCircleOutlineIcon />

                                </IconButton>
                            </Tooltip>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={actionDialogOpen ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={actionDialogOpen ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <MoreVertIcon />

                            </IconButton>
                            <Menu
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={actionDialogOpen}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                            >
                                <MenuItem
                                    component={Link}
                                    to={{
                                        pathname: "/transactiondividend",
                                    }}
                                    state={{ identifier: identifier, type: 'BUY' }}
                                >
                                    <ListItemText>Kauf</ListItemText>
                                    <ListItemIcon>
                                        <AddCircleOutlineIcon fontSize="small" />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem component={Link}
                                    to={{
                                        pathname: "/transactiondividend",
                                    }}
                                    state={{ identifier: identifier, type: 'SELL' }}
                                >
                                    <ListItemText>Verkauf</ListItemText>
                                    <ListItemIcon>
                                        <RemoveCircleOutlineIcon fontSize="small" />
                                    </ListItemIcon>
                                </MenuItem>
                                <MenuItem component={Link}
                                    to={{
                                        pathname: "/transactiondividend",
                                    }}
                                    state={{ identifier: identifier, type: 'DIVIDEND' }}
                                >
                                    <ListItemText>Dividende</ListItemText>
                                    <ListItemIcon>
                                        <AttachMoneyIcon fontSize="small" />
                                    </ListItemIcon>
                                </MenuItem>
                                <Divider />
                                <MenuItem component="div">
                                    <label htmlFor="fileInput">Logo upload</label>
                                    <input
                                        id="fileInput"
                                        accept="image/*"
                                        multiple
                                        type="file"
                                        onChange={handleFileInput}
                                        style={{ display: "none" }}
                                    />
                                </MenuItem>
                            </Menu>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid item xs={2} sm={1} lg={1} minWidth="100px">
                    <CompanyAvatar identifier={identifier ? identifier : '???'} alt={'Company Logo'}
                        sx={{
                            marginLeft: 3,
                            height: 70,
                            width: 70,
                        }}
                    >
                    </CompanyAvatar>
                </Grid>
                <Grid item xs={2} sm={4} lg={10}>
                    <Typography variant="h3" >
                        {security.length > 0 ? security[0].name : "Loading"}
                    </Typography>
                </Grid>
            </Grid>

            <Stack spacing={2} marginTop="10px">

                {!etfData && (<Smartbox alignItems="center">

                    <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 2, sm: 4, md: 8, lg: 12 }}>
                        <Grid item xs={2} mt={1}>
                            <Stack marginTop={1} >
                                <Typofield label="Identifier" value={security.length > 0 ? security[0].identifier : ""} copy={true} copyvalue={identifier} />
                                <Typofield label="Type" value={security.length > 0 ? security[0].type : ""} />
                                <Typofield label="ISIN" value={isin} copy={true} copyvalue={isin} />
                                <Typofield label="IPO-Datum" type="date" value={security.length > 0 ? security[0].ipodate : ""} />
                                <Typofield label="Anzahl Mitarbeiter" value={security.length > 0 ? security[0].fulltimeemployees : ""} />
                            </Stack>
                        </Grid>
                        <Grid item xs={4} mt={1}>
                            <Stack marginTop={1}  >
                                <Typofield label="Sektor" value={security.length > 0 ? security[0].sector : ""} />
                                <Typofield label="Industry" value={security.length > 0 ? security[0].industry : ""} />
                                <Typofield label="Branche" value={security.length > 0 ? security[0].gicsector : ""} />
                                <Typofield label="Branche Gruppe" value={security.length > 0 ? security[0].gicgroup : ""} />
                                <Typofield label="Detail" value={security.length > 0 ? security[0].gicsubindustry : ""} />
                            </Stack>
                        </Grid>
                        <Grid item xs={4} mt={1}>
                            <TextField
                                multiline
                                rows={5}
                                fullWidth
                                style={{ marginLeft: '2px', marginTop: '10px' }}
                                value={(security.length > 0 && security[0].description) ? security[0].description : ""}
                            />
                        </Grid>
                    </Grid>
                </Smartbox>)}
                {etfData && (<Smartbox alignItems="center">

                    <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 2, sm: 4, md: 8, lg: 12 }}>
                        <Grid item xs={4} mt={1}>
                            <Stack marginTop={1} >
                                <Typofield label="Identifier" value={etfData.identifier} copy={true} copyvalue={identifier} />
                                <Typofield label="Type" value="ETF" />
                                <Typofield label="ISIN" value={isin} copy={true} copyvalue={isin} />
                                <Typofield label="IPO-Datum" type="date" value={etfData.inception_date} />
                                <Typofield label="Index Name" value={etfData.index_name} />
                                <Typofield label="Fondsgröße" unit="Mio." type="number" value={etfData.totalassets / 1000000} />
                            </Stack>
                        </Grid>
                        <Grid item xs={4} mt={1}>
                            <Stack marginTop={1}  >
                                <Typofield label="Anbieter" value={etfData.company_name} />
                                <Typofield label="ETF URL" value={etfData.etf_url} copy={true} copyvalue={etfData.etf_url} />
                                <Typofield label="Fondsdomizil" value={etfData.domicile} />
                                <Typofield label="Ausschüttung" type="number" unit="%" value={etfData.yield} />
                                <Typofield label="Intervall" value={etfData.dividend_paying_frequency} />
                                <Typofield label="TER" type="number" unit="%" value={etfData.netexpenseratio * 100} />
                                <MUILink marginTop={1}
                                    underline="none"
                                    href={`https://de.extraetf.com/etf-profile/${isin}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    in extraETF öffnen
                                </MUILink>
                            </Stack>
                        </Grid>

                    </Grid>
                </Smartbox>)}

                {!etfData && (<Smartbox>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 2, sm: 4, md: 8, lg: 12 }}>
                        <Grid item xs={12} mb={1}>
                            <Typography variant="h4" mt={1}>
                                Highlights
                            </Typography>
                        </Grid>
                        <Grid item xs={2} mt={1}>
                            <Typoheader label="Kennzahlen" hint="KGV oder PE-Ratio: ???" />
                            <Stack>
                                <Typofield type="number" label="KGV (PE-Ratio)" value={highlights.length > 0 ? highlights[0].kgv : ""} />
                                <Typofield type="number" label="KGWV (PEG-Ratio)" value={highlights.length > 0 ? highlights[0].kgvgroth : ""} />
                                <Typofield type="number" label="Marktkap. Mrd." value={highlights.length > 0 ? highlights[0].marketcapmia : ""} unit={highlights.length > 0 ? getCurrencySymbol(highlights[0].cur) : ""} />
                                <Typofield type="number" label="Gewinnspanne" value={highlights.length > 0 ? highlights[0].gewinnspanne : ""} />
                                <Typofield type="number" label="Marge" value={highlights.length > 0 ? highlights[0].marge : ""} />
                                <Typofield type="number" label="ROA" value={highlights.length > 0 ? highlights[0].roa : ""} />
                                <Typofield type="number" label="ROE" value={highlights.length > 0 ? highlights[0].roe : ""} />
                            </Stack>
                        </Grid>
                        <Grid item xs={2} mt={1}>
                            <Typoheader label="Wachstum" hint="Umsatzwachstum: ???" />
                            <Stack>
                                <Typofield type="number" label="Umsatzwachstum" value={highlights.length > 0 ? highlights[0].umsatzwachstum : ""} />
                                <Typofield type="number" label="Gewinnwachstum" value={highlights.length > 0 ? highlights[0].gewinnwachstum : ""} />
                                <Typofield type="number" label="Gewinn / Aktie" value={highlights.length > 0 ? highlights[0].earningsshare : ""} />
                                <Typofield type="number" label="EPS Trend 10y" value={highlights.length > 0 ? highlights[0].epstrend10y : ""} />
                                <Typofield type="number" label="EPS Trend 3y" value={highlights.length > 0 ? highlights[0].epstrend3y : ""} />
                                <Typofield type="number" label="KBV" value={highlights.length > 0 ? highlights[0].kbv : ""} />
                                <Typofield type="number" label="Price to Book mrq" value={highlights.length > 0 ? highlights[0].pricebookmrq : ""} />
                            </Stack>
                        </Grid>
                        <Grid item xs={2} mt={1}>
                            <Typoheader label="Dividende & Analysten" hint="Umsatzwachstum: ???" />
                            <Stack>
                                <Typofield type="number" label="Dividendenredite" value={highlights.length > 0 ? highlights[0].dividendyield : ""} unit="%" />
                                <Typofield type="number" label="Dividende / Aktie" value={highlights.length > 0 ? highlights[0].dividendshare : ""} unit={highlights.length > 0 ? getCurrencySymbol(highlights[0].cur) : ""} />
                                <Typofield type="number" label="Erwartet" value={highlights.length > 0 ? highlights[0].forwardannualdividendrate : ""} unit={highlights.length > 0 ? getCurrencySymbol(highlights[0].cur) : ""} />
                                <Typofield type="number" label="Payout Ratio" value={highlights.length > 0 ? highlights[0].payoutratio * 100 : ""} />
                                <Typofield type="number" label="Wallstreet Target price" value={highlights.length > 0 ? highlights[0].wallstreettargetprice : ""} unit={highlights.length > 0 ? getCurrencySymbol(highlights[0].cur) : ""} />
                                <Typofield type="number" label="% zum Target" value={highlights.length > 0 ? highlights[0].prz2targetprice : ""} unit="%" />
                                <Typofield type="number" label="Analysten Rating" value={highlights.length > 0 ? highlights[0].rating : ""} />
                            </Stack>
                        </Grid>
                        <Grid item xs={2} mt={1}>
                            <Typoheader label="Aktie" hint="Umsatzwachstum: ???" />
                            <Stack>
                                <Typofield type="number" label="Kurs" value={highlights.length > 0 ? highlights[0].kurs : ""} unit={highlights.length > 0 ? getCurrencySymbol(highlights[0].cur) : ""} />
                                <Typofield type="date" label="Kurs Datum" value={highlights.length > 0 ? highlights[0].kursdatum : ""} />
                                <Typofield type="number" label="52 Wochen tief" value={highlights.length > 0 ? highlights[0].lo_250d : ""} unit={highlights.length > 0 ? getCurrencySymbol(highlights[0].cur) : ""} />
                                <Typofield type="number" label="52 Wochen hoch" value={highlights.length > 0 ? highlights[0].hi_250d : ""} unit={highlights.length > 0 ? getCurrencySymbol(highlights[0].cur) : ""} />
                                <Typofield type="number" label="Volumen" value={highlights.length > 0 ? highlights[0].volume : ""} />
                                <Typofield type="number" label="Volumen ø 14d" value={highlights.length > 0 ? highlights[0].avgvol_14d : ""} />
                            </Stack>
                        </Grid>
                        <Grid item xs={2} mt={1}>
                            <Typoheader label="Insiders" hint="Umsatzwachstum: ???" />
                            <Stack>
                                <Typofield label="Insider buys" value={highlights.length > 0 ? highlights[0].insiderbuys : ""} />
                                <Typofield label="Insider sells" value={highlights.length > 0 ? highlights[0].insidersells : ""} />
                                <Typofield type="number" label="Short ratio" value={highlights.length > 0 ? highlights[0].shortratio : ""} />
                                <Typofield type="number" label="Short %" value={highlights.length > 0 ? highlights[0].shortpercent : ""} unit="%" />
                            </Stack>
                        </Grid>
                    </Grid>
                </Smartbox>)}

                {pricePerformance && (<Smartbox2>
                    <Typography variant="body1" color="grey">
                        <span style={{ fontWeight: "bold" }}>Kurs Performance: </span>
                        <span style={{ fontWeight: "bold" }}>1M: </span>
                        {pricePerformance.price30D}%
                        <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;3M: </span>
                        {pricePerformance.price90D}%
                        <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;6M: </span>
                        {pricePerformance.price180D}%
                        <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;YTD: </span>
                        {pricePerformance.priceYTD}%
                        <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;1Y: </span>
                        {pricePerformance.price1Y}%
                        <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;3Y: </span>
                        {pricePerformance.price3Y}%
                        <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;5Y: </span>
                        {pricePerformance.price5Y}%
                        <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;10Y: </span>
                        {pricePerformance.price10Y}%
                        <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;Abstand 50T-Linie: </span>
                        {pricePerformance.ema_50d}%
                        <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp;Abstand 200T-Linie: </span>
                        {pricePerformance.ema_200d}%
                    </Typography>
                </Smartbox2>)}

                {divByYear && divByYear.length > 0 && (<Grid container>
                    <Grid item xs={12} sm={6} md={4}>
                        <Smartbox minHeight="280px">
                            <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 4, md: 8, lg: 12 }}>
                                <Grid item xs={12} mb={1}>
                                    <Typography variant="h4" >
                                        Ausschüttungen
                                    </Typography>
                                </Grid>
                                {perYear && (<Grid item xs={12} mt={1}>
                                    {getLimitedData(divByYear).map((row) => (
                                        <Typofield type="number" label={row.year} value={row.total_gross_amount} unit={getCurrencySymbol(row.currency)} />
                                    ))}
                                </Grid>)}
                                {!perYear && (<Grid item xs={12} mt={1}>
                                    {getLimitedData(dividends).map((row) => (
                                        <Typofield type="number" label={formatDateLong(row.date)} value={row.value} unit={getCurrencySymbol(row.currency)} />
                                    ))}
                                </Grid>)}
                                <Grid item xs={12} mt={1}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={!limit}
                                                    onChange={() => setLimit(!limit)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label={"Alle anzeigen"}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={perYear}
                                                    onChange={() => setPerYear(!perYear)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label={"pro Jahr"}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Smartbox>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <Smartbox height="280px" marginLeft={isMd ? 2 : 0}>
                            <BarChartDivYear identifier={identifier} height="280px" />
                        </Smartbox>

                    </Grid>
                </Grid>)}

                {portfolio && (<Smartbox>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                        <Grid item xs={12} mb={1}>
                            <Typography variant="h4" mt={1}>
                                Meine Performance
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} mt={1}>
                            <Typoheader label="Übersicht" hint="" />
                            <Stack>
                                <Typofield type="number" label="Bestand" value={portfolio ? portfolio.quantity : '???'} />
                                <Typofield type="stylednumber" label="Abs. Perf. 1 Tag" value={portfolio ? portfolio.profit_1d : '???'} unit='€' />
                                <Typofield type="date" label="Erster Kauf" value={portfolio ? portfolio.firstbuydate : '???'} />
                                <Typofield type="stylednumber" label="Abs. Performance %" value={portfolio ? portfolio.abs_performance_p : '???'} unit='%' bold='true' />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} mt={1}>
                            <Typoheader label="Wert" hint="" />
                            <Stack>
                                <Typofield type="number" label="Einstandskurs" value={portfolio ? portfolio.average_price : '???'} unit='€' />
                                <Typofield type="number" label="Einstandspreis" value={portfolio ? portfolio.total_buy_price : '???'} unit='€' />
                                <Typofield type="number" label="Aktueller Kurs" value={portfolio ? portfolio.close : '???'} unit={portfolio ? getCurrencySymbol(portfolio.currencycode) : '???'} />
                                <Typofield type="number" label="Marktwert ges." value={portfolio ? portfolio.total_market_price : '???'} unit='€' bold='true' />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} mt={1}>
                            <Typoheader label="Performance" hint={helpPerformance} />
                            <Stack>
                                <Typofield type="stylednumber" label="Gewinn / Verlust" value={portfolio ? portfolio.profit : '???'} unit='€' />
                                <Typofield type="stylednumber" label="Gewinn / Verlust %" value={portfolio ? portfolio.profit_p : '???'} unit='%' />
                                <Typofield type="stylednumber" label="Realisiert G/V" value={portfolio ? portfolio.realized_gains_losses : '???'} unit='€' />
                                <Typofield type="stylednumber" label="Abs. Performance" value={portfolio ? portfolio.abs_performance : '???'} unit='€' bold='true' />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} mt={1}>
                            <Typoheader label="Dividenden" hint="Diese Übersicht zeigt dir deine erhaltenen Dividendeneinkünfte, den Dividendenertrag als prozentualen Anteil an deinem Investment und die aktuelle Dividendenrendite deines Portfolios. Außerdem kannst du deine individuelle Dividendenrendite berechnen lassen, um deine persönliche Performance zu tracken." />
                            <Stack>
                                <Typofield type="number" label="∑ Dividenden Brutto" value={portfolio ? portfolio.dividends_brutto_total : '???'} unit='€' />
                                <Typofield type="number" label="Dividendenertrag gesamt in %" value={portfolio ? portfolio.dividend_yield_total : '???'} unit='%' />
                                <Typofield type="number" label="Dividendenrendite aktuell" value={portfolio ? portfolio.dividendyield : '???'} unit='%' />
                                <Typofield type="number" label="Meine Dividendenrendite" value={portfolio ? portfolio.owner_dividend_yield : '???'} unit='%' bold='true' />
                                <Typofield type="number" label="Dividende pro Jahr" value={portfolio ? portfolio.total_gross_dividend_in_eur : '???'} unit='€' bold='true' />

                            </Stack>
                        </Grid>
                    </Grid>
                </Smartbox>)}
                {portfolio && (<Smartbox>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }}>
                        <Grid item xs={4} >
                            <Typography variant="h4">
                                Meine Umsätze
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Stack spacing={0} direction="row" mt={2}>
                                <MenuItem
                                    component={Link}
                                    to={{
                                        pathname: "/transactiondividend",
                                    }}
                                    state={{ identifier: identifier, type: 'BUY' }}
                                >
                                    <ListItemIcon>
                                        <AddCircleOutlineIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Kauf</ListItemText>
                                </MenuItem>
                                <MenuItem component={Link}
                                    to={{
                                        pathname: "/transactiondividend",
                                    }}
                                    state={{ identifier: identifier, type: 'SELL' }}
                                >
                                    <ListItemIcon>
                                        <RemoveCircleOutlineIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Verkauf</ListItemText>
                                </MenuItem>
                                <MenuItem component={Link}
                                    to={{
                                        pathname: "/transactiondividend",
                                    }}
                                    state={{ identifier: identifier, type: 'DIVIDEND' }}
                                >
                                    <ListItemIcon>
                                        <AttachMoneyIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Dividende</ListItemText>
                                </MenuItem>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} >
                            <Porttransactions marginLeft={0} identifier={identifier} />
                        </Grid>
                    </Grid>
                </Smartbox>)}

                <Box height="400px" mb={10}>
                    <Linechart identifier={identifier} height="400px" />
                </Box>



            </Stack>

            {/* Neuer Abschnitt am Ende */}
            {lastAiResponse && (
                <Box
                    sx={{
                        backgroundColor: 'white', // Weißer Hintergrund
                        padding: 2,              // Innenabstand
                        borderRadius: 2,         // Abgerundete Ecken
                        boxShadow: 1,            // Leichter Schatten
                        marginTop: 5,           // Abstand zur oberen Kante
                        marginBottom: 4,         // Abstand zur unteren Kante
                        marginLeft: 1,            // Abstand zur linken Kante
                        marginRight: 1,           // Abstand zur rechten Kante
                    }}
                >
                    <Typography variant="h4" gutterBottom>
                        KI - Künstliche Intelligenz Analyse
                    </Typography>
                    {aiResponses.length > 0 && (
                        <Typography variant="h6" gutterBottom>
                            Analyse vom: {aiResponses.find(entry => entry.type === 'ANALYSIS') ? formatDateTime(aiResponses.find(entry => entry.type === 'ANALYSIS').date) : 'Datum nicht verfügbar'}
                            {' '}mit dem Modell: {aiResponses.find(entry => entry.type === 'ANALYSIS') ? aiResponses.find(entry => entry.type === 'ANALYSIS').model : 'Modell nicht verfügbar'}
                        </Typography>
                    )}
                    <ReactMarkdown>{lastAiResponse}</ReactMarkdown>

                    {/* Button zum Navigieren */}
                    <Box mt={3}>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="medium"
                            component={Link}
                            to={{
                                pathname: "/aiAnalyser",
                            }}
                            state={{ identifier: identifier }}
                        >
                            Zur KI-Analyse
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>

    );
};

export default Detailview;