import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Tooltip,
  Grid, useTheme, useMediaQuery, Avatar, Stack, Typography, Checkbox, FormControlLabel, FormGroup
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import FinDataGrid from "../components/findatagrid";
import Header from "../components/header";
import { tokens } from "../theme/theme";
import { formatDateTime } from "../misc/helperfunctions";

const Stocks = () => {
  const [rows, setRows] = useState([]);
  // eslint-disable-next-line
  const [identifier, setIdentifier] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // eslint-disable-next-line
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
    defaultMatches: true,
    noSsr: false
  });
  const [filterState, setFilterState] = useState({
    search: "",
    filters: {
      hasKiAnalysis: false,
      bestKiAnalysis: false,
      latestKiAnalysis: false,
      bestScreenerResults: false,
      marketcap1: false,
      marketcap5: false,
      dividendyield3: false,
    },
  });

  // Fetch rows with filters
  async function fetchRows() {
    try {
      setIsLoading(true);
      let url = process.env.REACT_APP_API_URI + "/api/securities?";
      const params = new URLSearchParams();

      console.log("Filter State:", filterState);
      if (filterState.search) {
        params.append("filter", filterState.search);
      }
      Object.entries(filterState.filters).forEach(([key, value]) => {
        if (value) {
          params.append(key, value);
        }
      });

      url += params.toString();
      const response = await fetch(url);
      const data = await response.json();
      setRows(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const savedSearch = localStorage.getItem("stocksSearch") || "";
    const savedFilters = JSON.parse(localStorage.getItem("stocksFilters")) || {};

    console.log("Saved Search:", savedSearch);
    console.log("Saved Filters:", savedFilters);

    setFilterState({
      search: savedSearch,
      filters: {
        ...filterState.filters,
        ...savedFilters,
      },
    });
  }, []);

  useEffect(() => {
    const fetchRowsWithDebounce = setTimeout(() => {
      fetchRows();
    }, 300);

    return () => clearTimeout(fetchRowsWithDebounce);
  }, [filterState]);


  const handleFilterChange = (event) => {
    const { name, checked } = event.target;
    console.log("handleFilterChange: ", name, checked);
    setFilterState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: checked,
      },
    }));
    localStorage.setItem(
      "stocksFilters",
      JSON.stringify({
        ...filterState.filters,
        [name]: checked,
      })
    );
  };


  const handleSearchClick = () => {
    console.log("handleSearchClick: fetchRows");
    fetchRows();
  };


  const handleInputChange = (event) => {
    const newSearch = event.target.value;
    setFilterState((prevState) => ({
      ...prevState,
      search: newSearch,
    }));
    localStorage.setItem("stocksSearch", newSearch);
  };


  const handleClearFilter = () => {
    inputRef.current.value = "";
    setFilterState((prevState) => ({
      ...prevState,
      search: "",
    }));
    localStorage.setItem("stocksSearch", "");
  };


  // Helper function
  function findRowById(id) {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].id === id) {
        return rows[i];
      }
    }
    return null;
  }

  // helper function
  function findRowByName(name) {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].name === name) {
        return rows[i];
      }
    }
    return null;
  }

  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      width: 40,
      renderCell: (params) => {
        const row = findRowById(params.id);
        let imageURL = process.env.REACT_APP_API_URI + '/images/' + row.identifier + '.png';
        if (!row.logourl) {
          imageURL = process.env.REACT_APP_API_URI + '/images/error.png';
        }
        return (
          <Avatar src={imageURL} alt={'Company Logo'}
            sx={{
              height: 30,
              width: 30
            }}
          >
          </Avatar>
        );
      }
    },
    {
      field: "name",
      headerName: "Name",
      width: smUp ? 220 : 180,
      renderCell: (params) => {
        const row = findRowByName(params.value);
        let cellClassName = "name-column--cell";
        if (row.profit < 0) {
          cellClassName = "negative-column--cell";
        }
        return (
          <Button color="inherit" variant="text" size="medium" component={Link}
            to={{
              pathname: "/detailview",
            }}
            state={{ identifier: row.identifier }}
            sx={{ m: 0, p: 0, minWidth: 0 }}
          >
            <Stack className={cellClassName} maxWidth={smUp ? 200 : 180}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{params.value}</Typography>
              <Typography variant="h7" noWrap>{row.isin} · {row.identifier}</Typography>
            </Stack>
          </Button>
        );
      }
    },
    {
      field: "marketcapitalization_billion",
      headerName: "Markt-Kap.",
      width: 100,
    },
    {
      field: "peratio",
      headerName: "KGV",
      width: 80,
    },
    {
      field: "country",
      headerName: "Country",
      width: 100,
    },
    {
      field: "currency",
      headerName: "Währung",
      width: 80,
    },
    {
      field: "type",
      headerName: "Type",
      width: 140,
    },
    {
      field: "avg_score",
      headerName: "KI-Score",
      width: 80,
    },
    {
      field: "last_date",
      headerName: "KI-Date",
      width: 140,
      type: "date",
      valueFormatter: (params) => {
        const valueFormatted = formatDateTime(params.value);
        return `${valueFormatted}`;
      },
    },
    {
      field: "dividendyield",
      headerName: "Div.%",
      width: 150,
    },
  ];

  return (
    <Box id="stockMainBox" m="20px" >
      <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center">
        <Grid item xs={3} sm={2}>
          <Header title="SCREENER" subtitle="" />
        </Grid>
        <Grid item xs={1} >
          <Box display="flex" justifyContent="center" ml={2} >
            {isLoading && (
              <CircularProgress color="inherit" />
            )}
          </Box>

        </Grid>

        <Grid item xs={12} >
          <Box
            display="flex"
            backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
            borderRadius="8px"
            height="40px"
            padding="0px 16px"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              ref={inputRef}
              value={filterState.search} // Dynamischer Wert aus filterState
              inputProps={{ spellCheck: 'false' }}
              placeholder="Search"
              onChange={handleInputChange} // Handler, der filterState aktualisiert
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  fetchRows(); // Aufruf der fetchRows-Funktion bei Enter
                }
              }}
            />
            <IconButton type="button" sx={{ p: 1 }} onClick={handleSearchClick}>
              <SearchIcon />
            </IconButton>
            <IconButton onClick={handleClearFilter}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {/* Filter Form */}
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  name="marketcap1"
                  checked={filterState.filters.marketcap1 || false}
                  onChange={handleFilterChange}
                />
              }
              label="Marktkapitalisierung > 1 Mrd."
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="marketcap5"
                  checked={filterState.filters.marketcap5 || false}
                  onChange={handleFilterChange}
                />
              }
              label="Marktkapitalisierung > 5 Mrd."
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="dividendyield3"
                  checked={filterState.filters.dividendyield3 || false}
                  onChange={handleFilterChange}
                />
              }
              label="Dividendenrendite > 3%"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          {/* Filter Form */}
          <FormGroup row>
            <Tooltip title="Nur Daten mit KI-Analyse anzeigen." arrow>
              <FormControlLabel
                control={
                  <Checkbox
                    name="hasKiAnalysis"
                    checked={filterState.filters.hasKiAnalysis || false}
                    onChange={handleFilterChange}
                  />
                }
                label="KI-Analyse vorhanden"
              />
            </Tooltip>
            <Tooltip title="Beste KI-Analysen mit KI-Score >= 8." arrow>
              <FormControlLabel
                control={
                  <Checkbox
                    name="bestKiAnalysis"
                    checked={filterState.filters.bestKiAnalysis || false}
                    onChange={handleFilterChange}
                  />
                }
                label="Beste KI-Analysen"
              />
            </Tooltip>
            <Tooltip title="Neueste KI-Analysen, die innerhalb der letzten 2 Tage durchgeführt wurden." arrow>
              <FormControlLabel
                control={
                  <Checkbox
                    name="latestKiAnalysis"
                    checked={filterState.filters.latestKiAnalysis || false}
                    onChange={handleFilterChange}
                  />
                }
                label="Neueste KI-Analysen"
              />
            </Tooltip>
            <Tooltip title="Beste Screener-Ergebnisse nach Durchlauf der KI-Analyse in drei Phasen." arrow>
              <FormControlLabel
                control={
                  <Checkbox
                    name="bestScreenerResults"
                    checked={filterState.filters.bestScreenerResults || false}
                    onChange={handleFilterChange}
                  />
                }
                label="Beste Screener-Ergebnisse"
              />
            </Tooltip>
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FinDataGrid
            key={JSON.stringify(filterState)}
            id="gridStocks"
            autoHeight
            density="standard"
            rowHeight={60}
            headerHeight={smUp ? 60 : 0}
            rows={rows}
            columns={columns}
            components={{
              Toolbar: smUp ? GridToolbar : '',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Stocks;
